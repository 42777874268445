body,html{
  height: 100%;
  margin: 0;
  background: #7F7FD5;
     background: -webkit-linear-gradient(to right, #91EAE4, #86A8E7, #7F7FD5);
      background: linear-gradient(to right, #91EAE4, #86A8E7, #7F7FD5);
}

.chat{
  margin-top: auto;
  margin-bottom: auto;
}
.card{
  height: 500px;
  border-radius: 15px !important;
  background-color: rgba(0,0,0,0.4) !important;
}
.contacts_body{
  padding:  0.75rem 0 !important;
  overflow-y: auto;
  white-space: nowrap;
}
.msg_card_body{
  overflow-y: auto;
}
.card-header{
  border-radius: 15px 15px 0 0 !important;
  border-bottom: 0 !important;
}
.card-footer{
border-radius: 0 0 15px 15px !important;
  border-top: 0 !important;
}
.container{
  align-content: center;
}
.search{
  border-radius: 15px 0 0 15px !important;
  background-color: rgba(0,0,0,0.3) !important;
  border:0 !important;
  color:white !important;
}
.search:focus{
     box-shadow:none !important;
       outline:0px !important;
}
.type_msg{
  background-color: rgba(0,0,0,0.3) !important;
  border:0 !important;
  color:white !important;
  height: 60px !important;
  overflow-y: auto;
}
  .type_msg:focus{
     box-shadow:none !important;
       outline:0px !important;
}
.attach_btn{
border-radius: 15px 0 0 15px !important;
background-color: rgba(0,0,0,0.3) !important;
  border:0 !important;
  color: white !important;
  cursor: pointer;
}
.send_btn{
border-radius: 0 15px 15px 0 !important;
background-color: rgba(0,0,0,0.3) !important;
  border:0 !important;
  color: white !important;
  cursor: pointer;
}
.search_btn{
  border-radius: 0 15px 15px 0 !important;
  background-color: rgba(0,0,0,0.3) !important;
  border:0 !important;
  color: white !important;
  cursor: pointer;
}
.contacts{
  list-style: none;
  padding: 0;
}
.contacts li{
  width: 100% !important;
  padding: 5px 10px;
  margin-bottom: 15px !important;
}
.active{
  background-color: rgba(0,0,0,0.3);
}
.user_img{
  height: 70px;
  width: 70px;
  border:1.5px solid #f5f6fa;

}
.user_img_msg{
  height: 40px;
  width: 40px;
  border:1.5px solid #f5f6fa;

}
.img_cont{
  position: relative;
  height: 70px;
  width: 70px;
}
.img_cont_msg{
  height: 40px;
  width: 40px;
}
.online_icon{
position: absolute;
height: 15px;
width:15px;
background-color: #4cd137;
border-radius: 50%;
bottom: 0.2em;
right: 0.4em;
border:1.5px solid white;
}
.offline{
background-color: #c23616 !important;
}
.user_info{
margin-top: auto;
margin-bottom: auto;
margin-left: 15px;
}
.user_info span{
font-size: 20px;
color: white;
}
.user_info p{
font-size: 10px;
color: rgba(255,255,255,0.6);
}
.video_cam{
margin-left: 50px;
margin-top: 5px;
}
.video_cam span{
color: white;
font-size: 20px;
cursor: pointer;
margin-right: 20px;
}
.msg_cotainer{
margin-top: auto;
margin-bottom: auto;
margin-left: 10px;
border-radius: 25px;
background-color: #82ccdd;
padding: 10px;
position: relative;
}
.msg_cotainer_send{
margin-top: auto;
margin-bottom: auto;
margin-right: 10px;
border-radius: 25px;
background-color: #78e08f;
padding: 10px;
position: relative;
}
.msg_time{
position: absolute;
left: 0;
bottom: -15px;
color: rgba(255,255,255,0.5);
font-size: 10px;
}
.msg_time_send{
position: absolute;
right:0;
bottom: -15px;
color: rgba(255,255,255,0.5);
font-size: 10px;
}
.msg_head{
position: relative;
}
#action_menu_btn{
position: absolute;
right: 10px;
top: 10px;
color: white;
cursor: pointer;
font-size: 20px;
}
.action_menu{
z-index: 1;
position: absolute;
padding: 15px 0;
background-color: rgba(0,0,0,0.5);
color: white;
border-radius: 15px;
top: 30px;
right: 15px;
display: none;
}
.action_menu ul{
list-style: none;
padding: 0;
margin: 0;
}
.action_menu ul li{
width: 100%;
padding: 10px 15px;
margin-bottom: 5px;
}
.action_menu ul li i{
padding-right: 10px;

}
.action_menu ul li:hover{
cursor: pointer;
background-color: rgba(0,0,0,0.2);
}
@media(max-width: 576px){
.contacts_card{
margin-bottom: 15px !important;
}
}

.link{
color:white;

}
title{

color: white;

}

.renk{

color:white;

}

.loginKapsayici{

margin-top: 100px;
overflow: hidden;

height: auto;

}



.colPadding{

background-color: #fffdfd6e;

height: auto;

margin-top:170px;
border-radius: 20px;
overflow: hidden;
}

#googlebutton{

margin: 10px 0px;

width:100%;
box-shadow: 5px 5px 6px rgba(83, 81, 81, 0.774);
border-radius: 5px;
padding: 16px 2px;


}

.googleWrite{

color: black;
font-style: italic;
font-family: 'Times New Roman', Times, serif;
text-align: center;
background-color: #7F7FD5;
border-radius: 15px;
padding: 10px 5px;

box-shadow: 5px 13px 6px rgb(73, 72, 72);
}

.googleWrite:hover{

background-color: rgba(0, 0, 0, 0.753);
color: white;
}


.border{

border: 2px solid black;
border-radius: 15px;
height: 219px;

background-color:cornflowerblue;
overflow: hidden;
height: auto;
}


.sohbetresim{

  width: 100%;
  height: 350px;
margin: 5px 0px;

}

.loginForm{

/*background-color: #7F7FD5;*/

overflow: hidden;
height: 280px;
border-radius: 10px;

z-index: 1;
}

.loginForm form{

width: 100%;

}

.loginInput{

margin: 20px 0px;



}
.loginwrite{

color:blueviolet;

}

.buttonLogin{

background-color: #c23616;

padding: 5px 9px;
border-radius: 5px;
color: white;
width: 100%;

}

.blackBox{


height: 280px;
background-color: rgba(255, 255, 255, 0.3);
-webkit-backdrop-filter: blur(18px);
backdrop-filter: blur(18px);
box-shadow: 0px 20px 18px rgba(0, 0, 0, 0.30);
letter-spacing: 0.75em;

}

.errorLogin{

color: red;

}

.logoutButton{


background-color: red;
color: white;
padding: 0px 10px;
width:100px;
}

.registerLink{

  letter-spacing: 0em;

color:darkslategrey;


}

.RegisterKapsayici{

overflow: hidden;

margin-top: 100px;

height: 680px;
}


.RegisterForm{


  overflow: hidden;
  height: 680px;
  border-radius: 10px;
  
  z-index: 1;

}

.blackRegisterBox{

  height: 480px;
  background-color: rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(18px);
  backdrop-filter: blur(18px);
  box-shadow: 0px 20px 18px rgba(0, 0, 0, 0.30);
  letter-spacing: 0.75em;
  


}

.KullaniciAdi{

background-color: #7fc485;
font-size: 13px;
padding: 0px 5px;
width: auto;
overflow: hidden;

border-radius: 18px;

}

.usernameWrite{

font-family:Arial, Helvetica, sans-serif;
font-style: italic;
font-weight: 700px;
position: relative;
top: 10px;

}

.cardKapsayici{


}

.gizliEmoji{



}



.EmojiBox{

width: 100%;
height: 100px;
background-color: white;
position: absolute;
border-radius: 20px;
}


.iconEmoji{

  width: 30px;
  height: 30px;
  float: left;
  margin: 5px 5px;

  }

  
.iconEmojiPicture{


  width: 30px;
  height: 30px;
 
  margin: 5px 10px;


}

.Emojiwrite{

margin-top: 7px;
 
  float: left;

background-color: #7F7FD5;
padding: 5px 5px;
border-radius: 10px;
color: #f5f6fa;

margin-left: 5px;

}


.formBox{

float: left;

margin: 0px 7px;



}

.pm_write{

float: left;
margin:0px 10px;

}

.beyaz_write{

color: white;

}

.kirmizi_write{

background-color: #c23616;
width: auto;
height: 15px;
border-radius: 10px;
color: white;
margin: 0px 5px;
overflow: hidden;
}

.cat{

width:100%;
overflow: hidden;


}

.cat_picture{

width: 100%;

}



.formkutum{

width: 50%;
height: auto;

  margin: auto;
  
  }
.sifre{

width: 100%;

}

.odabutton{
margin-top: 10px;
background-color: #7bda8f;
width: 40%;
position: relative;
left: 28%;
}